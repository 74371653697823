<template>
  <aside
    class="zui-sidebar"
    :class="$store.state.sidebarFold ? 'sidebarFold' : 'sidebarOpen'"
  >
    <div class="zui-sidebar__inner">
      <el-menu
        :default-active="$route.name"
        class="zui-sidebar__menu"
        active-text-color="#fff"
        :collapse="$store.state.sidebarFold"
        :collapse-transition="false"
      >
        <sub-menu v-for="menu in menuList" :key="menu.id" :menu="menu" />

        <!-- <template v-for="item in menuList">
          <template v-if="item.children.length > 0">
            <el-submenu :key="item.id" :index="item.id">
              <template slot="title">
                <svg v-if="item.img" class="icon-svg" aria-hidden="true">
                  <use :xlink:href="`#${item.img}`"></use>
                </svg>

                <i v-else class="el-icon-location"></i>
                <span>{{ item.name }}</span>
              </template>

              <el-menu-item
                :index="child.rName"
                v-for="child in item.children"
                :key="child.id"
                @click="goRouteHandler(child.rName)"
              >
                <template slot="title">
                  <span style="padding-left: 5px">{{ child.name }}</span>
                </template>
              </el-menu-item>
            </el-submenu>
          </template>

          <template
            v-else-if="
              $store.state.sidebarFold &&
              (!item.children || item.children.length == 0)
            "
          >
            <el-submenu :key="item.id" :index="item.id">
              <template slot="title">
                <svg v-if="item.img" class="icon-svg" aria-hidden="true">
                  <use :xlink:href="`#${item.img}`"></use>
                </svg>

                <i v-else class="el-icon-location"></i>
                <span>{{ item.name }}</span>
              </template>

              <el-menu-item
                :key="item.id"
                :index="item.rName"
                @click="goRouteHandler(item.rName)"
              >
                <template slot="title">
                  <svg v-if="item.img" class="icon-svg" aria-hidden="true">
                    <use :xlink:href="`#${item.img}`"></use>
                  </svg>

                  <i v-else class="el-icon-location"></i>
                  <span>{{ item.name }}</span>
                </template>
              </el-menu-item>
            </el-submenu>
          </template>

          <template v-else>
            <el-menu-item
              :key="item.id"
              :index="item.rName"
              @click="goRouteHandler(item.rName)"
            >
              <template slot="title">
                <svg v-if="item.img" class="icon-svg" aria-hidden="true">
                  <use :xlink:href="`#${item.img}`"></use>
                </svg>

                <i v-else class="el-icon-location"></i>
                <span>{{ item.name }}</span>
              </template>
            </el-menu-item>
          </template>
        </template> -->
      </el-menu>
    </div>
  </aside>
</template>

<script>
import SubMenu from "./main-sidebar-sub-menu.vue";

export default {
  components: {
    SubMenu,
  },
  data() {
    return {
      menuList: [],
    };
  },
  created() {
    this.getMenuList();
  },
  methods: {
    getMenuList() {
      this.menuList = window.SITE_CONFIG["menuList"];
    },

    // 菜单路由跳转
    goRouteHandler(rName) {
      // if (rName == "monitor") {
      //   window.open(window.SITE_CONFIG["jumpUrl"], "_blank");
      //   this.$router.go(0);
      // }

      if (this.$route.name == rName) return;

      this.$router.push({ name: rName });
    },
  },
};
</script>

<style lang="scss" scoped>
.zui-sidebar {
  z-index: 99;
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 230px;
  // background-color: #263238;
  transition: 0.3s;
  // box-shadow: -2px 0 12px 0 rgba($color: #000000, $alpha: 0.2);
  overflow: hidden;
  background-color: #fff;

  .zui-sidebar__inner {
    width: calc(100% + 16px);
    height: calc(100vh - 60px);
    overflow-y: scroll;
    position: absolute;
    top: 0;
    left: 0;
  }
}

:deep() {
  .el-menu {
    .el-menu-item.is-active {
      background-color: rgba(17, 187, 141, 0.8);
    }

    .el-menu-item.is-active:hover {
      background-color: rgba(17, 187, 141, 0.8);
    }

    .el-submenu__title:hover {
      background-color: rgba(17, 187, 141, 0.2);
    }

    .el-menu-item:hover {
      background-color: rgba(17, 187, 141, 0.2);
    }
  }

  .zui-sidebar__menu {
    border-right: none;
    text-align: left;
  }
}

.icon-svg {
  width: 1rem;
  height: 1rem;
  fill: currentColor;
  margin-right: 8px;
}
</style>