<template>
  <div>
    <template v-if="menu.children.length > 0">
      <el-submenu :key="menu.id" :index="menu.id">
        <template slot="title">
          <svg v-if="menu.img" class="icon-svg" aria-hidden="true">
            <use :xlink:href="`#${menu.img}`"></use>
          </svg>

          <i v-else class="el-icon-location"></i>
          <span>{{ menu.name }}</span>
        </template>

        <sub-menu
          v-for="item in menu.children"
          :key="item.id"
          :menu="item"
        ></sub-menu>
      </el-submenu>
    </template>

    <template
      v-else-if="
        $store.state.sidebarFold &&
        (!menu.children || menu.children.length == 0)
      "
    >
      <el-submenu :key="menu.id" :index="menu.id">
        <template slot="title">
          <svg v-if="menu.img" class="icon-svg" aria-hidden="true">
            <use :xlink:href="`#${menu.img}`"></use>
          </svg>

          <i v-else class="el-icon-location"></i>
          <span>{{ menu.name }}</span>
        </template>

        <el-menu-item
          :key="menu.id"
          :index="menu.rName"
          @click="goRouteHandler(menu.rName)"
        >
          <template slot="title">
            <svg v-if="menu.img" class="icon-svg" aria-hidden="true">
              <use :xlink:href="`#${menu.img}`"></use>
            </svg>

            <i v-else class="el-icon-location"></i>
            <span>{{ menu.name }}</span>
          </template>
        </el-menu-item>
      </el-submenu>
    </template>

    <template v-else>
      <el-menu-item
        :key="menu.id"
        :index="menu.rName"
        @click="goRouteHandler(menu.rName)"
      >
        <template slot="title">
          <svg v-if="menu.img" class="icon-svg" aria-hidden="true">
            <use :xlink:href="`#${menu.img}`"></use>
          </svg>

          <i v-else class="el-icon-location"></i>
          <span>{{ menu.name }}</span>
        </template>
      </el-menu-item>
    </template>
  </div>

  <!-- <div>
    <el-submenu
      v-if="menu.children && menu.children.length >= 1"
      :index="menu.id"
      :popper-append-to-body="false"
    >
      <template slot="title">
        <svg v-if="item.img" class="icon-svg" aria-hidden="true">
          <use :xlink:href="`#${menu.img}`"></use>
        </svg>
        <span>{{ menu.name }}</span>
      </template>
      <sub-menu
        v-for="item in menu.children"
        :key="item.id"
        :menu="item"
      ></sub-menu>
    </el-submenu>
    <el-menu-item v-else :index="menu.id" @click="gotoRouteHandle(menu.id)">
      <svg class="icon-svg aui-sidebar__menu-icon" aria-hidden="true">
        <use :xlink:href="`#${menu.img}`"></use>
      </svg>
      <span>{{ menu.name }}</span>
    </el-menu-item>
  </div> -->
</template>
  
  <script>
import SubMenu from "./main-sidebar-sub-menu";
export default {
  name: "sub-menu",
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
  components: {
    SubMenu,
  },
  methods: {
    // 通过menuId与动态(菜单)路由进行匹配跳转至指定路由
    // 菜单路由跳转
    goRouteHandler(rName) {
      // if (rName == "monitor") {
      //   window.open(window.SITE_CONFIG["jumpUrl"], "_blank");
      //   this.$router.go(0);
      // }

      if (this.$route.name == rName) return;

      this.$router.push({ name: rName });
    },
    gotoRouteHandle(menuId) {
      var route = window.SITE_CONFIG["dynamicMenuRoutes"].filter(
        (item) => item.meta.menuId === menuId
      )[0];
      if (route) {
        this.$router.push({ name: route.name });
      }
    },
  },
};
</script>
  
<style lang="scss" scoped>
.zui-sidebar {
  z-index: 99;
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 230px;
  // background-color: #263238;
  transition: 0.3s;
  // box-shadow: -2px 0 12px 0 rgba($color: #000000, $alpha: 0.2);
  overflow: hidden;
  background-color: #fff;

  .zui-sidebar__inner {
    width: calc(100% + 16px);
    height: calc(100vh - 60px);
    overflow-y: scroll;
    position: absolute;
    top: 0;
    left: 0;
  }
}

:deep() {
  .el-menu {
    .el-menu-item.is-active {
      background-color: rgba(17, 187, 141, 0.8);
    }

    .el-menu-item.is-active:hover {
      background-color: rgba(17, 187, 141, 0.8);
    }

    .el-submenu__title:hover {
      background-color: rgba(17, 187, 141, 0.2);
    }

    .el-menu-item:hover {
      background-color: rgba(17, 187, 141, 0.2);
    }
  }

  .zui-sidebar__menu {
    border-right: none;
    text-align: left;
  }
}

.icon-svg {
  width: 1rem;
  height: 1rem;
  fill: currentColor;
  margin-right: 8px;
}
</style>