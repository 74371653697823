<template>
  <nav class="zui-navbar">
    <div
      class="zui-navbar__header"
      :class="$store.state.sidebarFold ? 'sidebarFold' : 'sidebarOpen'"
    >
      <span class="text">{{
        $store.state.sidebarFold ? "平安" : "平安巴士管理系统"
      }}</span>
    </div>

    <div class="zui-navbar__body">
      <div class="zui-navbar__menu menu-left">
        <div
          class="fold-switch"
          @click="$store.state.sidebarFold = !$store.state.sidebarFold"
        >
          <i
            :class="
              $store.state.sidebarFold ? 'el-icon-s-unfold' : 'el-icon-s-fold'
            "
          ></i>
        </div>
      </div>

      <div class="zui-navbar__menu menu-right">
        <div class="notice-wrapper" @click="openNoticeHandler">
            <i class="el-icon-s-opportunity"></i>
        </div>

        <div class="user-loginOut">
          <el-dropdown placement="bottom" :show-timeout="0">
            <div class="content">
              <!-- src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg" -->
              <el-image
                class="img"
                round
                src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg""
              ></el-image>
              <span class="text">{{$store.state.userdata.nickName}}</span>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="loginOutHandler"
                >退出</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>

    <!-- 自定义组件 -->
    <template>
      <notice ref="NoticeDom" v-if="notice_visible" />
    </template>
  </nav>
</template>

<script>
import Notice from "@/components/notice";
import { clearLoginInfo } from "@/utils/index.js";

export default {
  components: {
    Notice,
  },
  data() {
    return {
      notice_visible: false,
    };
  },
  created() {
    let tipList = this.$store.state.tipList;
    if (tipList && tipList.length > 0) {
      this.notice_visible = true;
      this.$nextTick(() => {
        this.$refs.NoticeDom.init();
      });
    }
  },
  methods: {
    loginOutHandler() {
      this.$confirm("确认退出当前系统", "温馨提示", {
        type: "warning",
      })
        .then(() => {
          // 清除用户登录信息
          clearLoginInfo();
          this.$router.push({ name: "login" });
        })
        .catch(() => {});
    },
    // 打开提示
    openNoticeHandler() {
      this.notice_visible = true;
      this.$nextTick(() => {
        this.$refs.NoticeDom.init();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.zui-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  line-height: 60px;
  background-color: #fff;
  display: flex;
  z-index: 99;
  box-shadow: 0 -5px 10px 0 rgba($color: #000000, $alpha: 0.5);
}

.zui-navbar__header {
  width: 230px;
  text-align: center;
  transition: width 0.3s;
  .text {
    cursor: pointer;
    font-size: 20px;
    font-weight: 550;
    color: #11bb8d;
    white-space: nowrap;
  }
}

.zui-navbar__body {
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  .zui-navbar__menu {
    .fold-switch {
      padding: 0 20px;
      transition: 0.2s;
      cursor: pointer;
      color: #11bb8d;
    }
    .fold-switch:hover {
      background-color: rgba($color: #11bb8d, $alpha: 0.2);
    }

    .user-loginOut {
      height: 60px;
      cursor: pointer;
      transition: 0.3s;

      .text {
        font-weight: 550;
      }
      .content {
        padding: 0 20px;
        display: flex;
        align-items: center;
        .img {
          width: 38px;
          height: 38px;
          border-radius: 50%;
          margin-right: 5px;
        }
      }
    }
    .user-loginOut:hover {
      background-color: rgba($color: #11bb8d, $alpha: 0.8);
      .text {
        color: #fff;
      }
    }
  }

  .menu-right {
    display: flex;
    .notice-wrapper {
      padding: 0 10px;
      cursor: pointer;
      transition: 0.3s;
    }
    .notice-wrapper:hover {
      background-color: rgba($color: #11bb8d, $alpha: 0.8);
      color: #fff;
    }
  }
}
</style>