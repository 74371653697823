<template>
  <div
    class="zui-wrapper"
    :style="
      $store.state.sidebarFold ? 'padding-left: 64px' : 'padding-left: 230px'
    "
  >
    <main-navbar />
    <main-sidebar />

    <div class="zui-content__wrapper">
      <main-content />
    </div>

    <el-backtop />
  </div>
</template>

<script>
import MainNavbar from "./main-navbar.vue";
import MainSidebar from "./main-sidebar.vue";
import MainContent from "./main-content.vue";

export default {
  components: {
    MainNavbar,
    MainSidebar,
    MainContent,
  },
  created() {

  },
};
</script>

<style lang="scss" scoped>
.zui-wrapper {
  padding-top: 60px;
  padding-left: 230px;
  transition: 0.3s;
}

.zui-content__wrapper {
  min-width: 800px;
  min-height: calc(100vh - 60px);
  background-color: #f1f4f5;
  padding: 20px;
}
</style>