<template>
  <el-dialog
    :visible.sync="visible"
    title="待办项"
    @close="closeHandler"
    append-to-body
  >
    <div class="tip-content">
      <div class="tip-list">
        <template v-if="tipList && tipList.length > 0">
          <div class="tip-item" v-for="(item, index) in tipList" :key="index">
            {{ item }}
          </div>
        </template>
        <template v-else> 暂无任务</template>
      </div>
    </div>
    <template slot="footer">
      <el-button type="primary" @click="visible = false">确认</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    tipList() {
      return this.$store.state.tipList || null;
    },
  },
  methods: {
    init() {
      this.visible = true;
    },
    closeHandler() {
      console.log(123);
      return;
      this.$store.commit("saveTipList", null);
    },
  },
};
</script>